body
	&.cl
		header
			.headerTop
				.hTopRight
					.hTopRightExtra
						.selCountry
							.currentFlag
								// &:before
								// 	background: url(assets/vector/flag-chile.svg) no-repeat 0 0
		.mSolicitar
			.mSolTitle
				text-align: left
			.mSolBundle
				+justifyCont(flex-start)
			.mSolSubs 
				[class*="btn"]
					background: #fff
					color: $gris5
					+font-size(14px)
					line-height: 14px
					font-weight: 700
					border: 1px solid $gris1
					box-shadow: 0px 1px 4px rgba(45, 45, 45, 0.2)
					padding-right: 50px
					&::after
						+claroIcon('e930')
						position: absolute
						right: 20px
						+font-size(20px)
					i
						left: 20px
						margin-left: 0
		.mServiceHome 
			.mServiceHomeMain 
				.mServiceHomeCont 
					.mServiceHomeBody 
						.mServiceHomeAmount 
							.mServiceHomeAmountCont 
								.mServiceHomePrices
									.symbol
										p
											color: $rojo6
									.mServiceHomePrice
										p
											color: $rojo6
									
									.decimal
										p
											color: $rojo6